.form {
	position: relative;
	padding: 24px;
	border-radius: 20px;
	color: #fff;

	@media (min-width: 768px) {
		padding: 36px 40px;
	}

	@media (min-width: 1280px) {
		padding: 24px 30px;
	}

	&__content {
		position: relative;
	}

	&__bg-box {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		border-radius: 20px;
		overflow: hidden;
		pointer-events: none;
	}

	&__bg {
		width: 100%;
		height: 100%;
		background-color: rgba(21, 34, 52, 0.15);
		backdrop-filter: blur(20px);
	}

	&__title {
		font-weight: 700;
		font-size: 16px;
		line-height: 26px;
		margin-bottom: 10px;
		letter-spacing: -0.3px;

		@media (min-width: 768px) {
			font-size: 20px;
			line-height: 32px;
			margin-bottom: 8px;
		}

		@media (min-width: 1280px) {
			margin-bottom: 5px;
		}
	}

	&__text {
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 27px;
		padding-right: 15px;

		@media (min-width: 768px) {
			margin-bottom: 30px;
		}
	}

	&__messengers {
		padding-bottom: 8px;

		@media (min-width: 768px) {
			padding-bottom: 16px;
		}

		@media (min-width: 1280px) {
			padding-bottom: 20px;
		}
	}

	&__form-group {
		position: relative;
		margin-bottom: 12px;

		@media (min-width: 768px) {
			display: flex;
		}
	}

	&__form-error {
		position: absolute;
		font-size: 12px;
		line-height: 12px;
		color: #d0103a;
		top: 5px;
		right: 5px;
		pointer-events: none;

		@media (min-width: 768px) {
			right: auto;
			left: calc(50% - 60px);
		}
	}

	&__phone {
		border: 1px solid transparent;
		border-radius: 10px;

		&.react-tel-input {
			height: 54px;
			width: 100%;
			margin-bottom: 8px;

			@media (min-width: 768px) {
				width: calc(50% - 4px);
				margin-right: auto;
				margin-bottom: 0;
			}

			@media (min-width: 1280px) {
				width: calc(50% - 6px);
			}

			.form-control {
				width: 100%;
				height: 100%;
				border-radius: 10px;
				font-weight: 700;
				font-size: 14px;
				line-height: 22px;
				color: #ccc;

				&:focus,
				&:hover {
					color: #333;
				}
			}

			.flag-dropdown {
				width: 45px;
				border-radius: 10px 0 0 10px;
			}

			.country-list {
				max-height: 140px;
				margin-bottom: 0;

				.country-name {
					color: #333;
				}
			}

			.selected-flag {
				width: 42px;
			}
		}

		&.form__phone--error {
			border-color: #d0103a;
		}

		&.form__phone--filled {
			.form-control {
				color: #333;
			}
		}
	}

	&__agreement {
		font-weight: 500;
		font-size: 12px;
		line-height: 20px;
		opacity: 0.5;
		margin-bottom: 8px;
		letter-spacing: -0.3px;

		@media (min-width: 768px) {
			margin-bottom: 20px;
		}

		a {
			color: #fff;
			transition: color 0.25s linear;

			&:hover {
				color: var(--color-theme, #d0103a);
			}
		}
	}

	&__info {
		display: block;
		font-weight: 500;
		font-size: 12px;
		line-height: 20px;

		@media (min-width: 768px) {
			display: inline-block;
			margin-right: 25px;
		}
	}

	&__image-box {
		position: absolute;
		width: 113px;
		height: 92px;
		z-index: 1;
		top: 20%;
		right: -30px;
		pointer-events: none;

		img {
			max-width: 100%;
			max-height: 100%;
		}

		@media (min-width: 768px) {
			width: 175px;
			height: 164px;
			top: 10%;
			right: -90px;
		}
	}

	& &__form-input {
		margin-bottom: 8px;

		@media (min-width: 768px) {
			width: calc(50% - 4px);
			margin-right: auto;
			margin-bottom: 0;
		}

		@media (min-width: 1280px) {
			width: calc(50% - 6px);
		}
	}

	& &__form-button {
		@media (min-width: 768px) {
			width: calc(50% - 4px);
		}

		@media (min-width: 1280px) {
			width: calc(50% - 6px);
		}
	}

	&.form--v-2 {
		.form__text {
			@media (min-width: 1280px) {
				margin-bottom: 32px;
			}
		}

		.form__form-group {
			@media (min-width: 1280px) {
				margin-bottom: 7px;
			}
		}

		.form__agreement {
			@media (min-width: 1280px) {
				margin-bottom: 14px;
			}
		}

		.form__image-box {
			@media (min-width: 1280px) {
				top: 5%;
				right: -105px;
			}
		}

		.messengers__item {
			@media (min-width: 1280px) {
				min-width: 105px;
				margin-right: 4px;
			}

			label {
				@media (min-width: 1440px) {
					padding: 4px 6px;
				}
			}
		}

		.form__messengers {
			@media (min-width: 1280px) {
				display: flex;
				align-items: center;
			}

			.messengers__text {
				@media (min-width: 1280px) {
					margin-bottom: 0;
					margin-right: 15px;
				}
			}
		}

		@media (min-width: 1280px) {
			margin-right: auto;
		}

		@media (min-width: 1440px) {
			padding: 26px 32px;
		}
	}

  .react-tel-input .selected-flag:hover,
  .react-tel-input .selected-flag:focus {
    border-radius: 8px 0 0 8px;
  }
}
