.header {
  position: relative;
  display: flex;
  width: 100%;
  padding: 15px 16px 0;
  margin-bottom: 25px;

  @media (min-width: 768px) {
    align-items: center;
    padding: 20px 32px 0;
    margin-bottom: 59px;
  }

  @media (min-width: 1280px) {
    padding: 26px 48px 0;
    margin-bottom: 20px;
  }

  &__logo {
    display: flex;
    width: 160px;
    height: 30px;

    @media (min-width: 768px) {
      margin-right: 24px;
    }

    @media (min-width: 1280px) {
      margin-right: 27px;
    }

    svg {
      width: 100%;
      height: 100%;
      fill: #d0103a;
    }

     img {
       max-width: 100%;
       max-height: 100%;
     }
  }

  &__social-list {
    display: flex;
    margin-left: auto;
  }

  &__expert {
    display: none !important;

    @media (min-width: 768px) {
      display: flex !important;
      margin-right: auto;
    }
  }

  &__social {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 4px;
    transition: background-color 0.25s linear;

    @media (min-width: 768px) {
      margin-left: 8px;
    }

    svg {
      width: 50%;
      height: 50%;
      fill: #fff;
      transition: fill 0.25s linear;
    }

    &--whatsapp {
      background-color: #25d366;

      @media (min-width: 1280px) {
        &:hover {
          background-color: #fff;

          svg {
            fill: #25d366;
          }
        }
      }
    }

    &--telegram {
      background-color: #0088cc;

      @media (min-width: 1280px) {
        &:hover {
          background-color: #fff;

          svg {
            fill: #0088cc;
          }
        }
      }
    }

    &--call {
      background-color: #2aaf5c;

      @media (min-width: 768px) {
        display: none;
      }
    }

    @media (min-width: 768px) {
      width: 40px;
      height: 40px;
    }
  }

  &__call-box {
    display: none;

    @media (min-width: 768px) {
      display: block;
      padding-left: 17px;
    }

    @media (min-width: 1280px) {
      padding-left: 25px;
    }
  }

  &__phone {
    display: block;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: white;
  }

  &__call {
    display: block;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: var(--color-theme, #d0103a);
    background-color: transparent;
    cursor: pointer;
    transition: color 0.25s, filter 0.25s linear;

    @media (min-width: 1280px) {
      &:hover {
        filter: brightness(85%);
      }
    }
  }
}
